import React from 'react'
import { Alert, Badge } from 'react-bootstrap'
import { IComment } from '../../types/models/Comment'
import moment from 'moment'

type Props = {
  comment: IComment
}

export const Comment: React.FC<Props> = ({ comment }) => {
  let className = 'primary'
  if (comment.status === 1) {
    className = 'danger'
  }



  return (
    <Alert variant='primary' className={`alert-${className} rounded-0 my-1`}>
      <Badge bg={className} className='rounded-0'>{moment(comment.created_at).toDate().toLocaleDateString()}</Badge> {comment.content}
    </Alert>
  )
}